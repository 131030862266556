import React from "react";
import './App.css';

function App() {
  return (
    <div className="App">
          <img className="cora" src="./cora.jpeg" alt="My love"/>
      <b>
      <p><span>Happy 15th birthday! 🎉🎉🎉</span><br/>Today marks such a special milestone as you step further into the years, and I couldn't be more excited to celebrate with you and to walk to the future with you. Growing for the past year has been a privilege every day, you become even more of the incredible person you are meant to be. Your kindness touches everyone around you, your strength inspires others, and your beauty, inside and out, shines brightly in everything you do. Your time with me is treasured and recollected at an interval.</p>
      <img src="./heart.png" alt="heart" />
      <img src="./heart.png" alt="heart" />
      <img src="./heart.png" alt="heart" />
<p>As you embrace this new chapter of your life, know that it’s filled with endless possibilities. This time frame of your life are a time to dream boldly, explore new horizons, and discover who you truly are. Don't be afraid to chase after your dreams, no matter how big or small, because I believe in you and your ability to achieve anything you set your heart on. Challenges may come, but always remember that you are strong enough to face them, and they will only help you grow. Please also remember that you have me to shoulder your burden and that I also love you.</p>
<img src="./heart.png" alt="heart" />
<img src="./heart.png" alt="heart" />
<img src="./heart.png" alt="heart" />

<p>May the rest of the year and the years to come bring you countless moments of laughter, unforgettable adventures, and the kind of memories that will stay with you forever.</p>
<img src="./heart.png" alt="heart" />
<img src="./heart.png" alt="heart" />
<img src="./heart.png" alt="heart" />

<p>I’m wishing you not just a fantastic birthday, but a year filled with love, joy, and all the happiness in the world. Keep shining your light, and always remember how special you are to everyone who knows you, especially I. You are my everything.</p>

</b>
<footer>
  From your husband, with love ❤️ <br/>
  Happy birthday, my darling ❤️ <br/>
  <iframe
        title="You know what this is about"
        src="https://www.youtube.com/embed/5hKzDoJ6Ue8?si=aVnYwYGd2w2eVajN"
      ></iframe>
</footer>
    </div>
  );
}

export default App;
